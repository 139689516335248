<template>
  <div>
    <viewcard--c :title="$route.params.id > 0
        ? 'Atualizar Grupo de acesso'
        : 'Cadastrar Grupo de acesso'
      " :btsave="$route.params.id > 0 ? btedit : btcreate" :btdelete="$route.params.id > 0 ? btdelete : null"
      :btback="{}" :busy="loading" @clicked-save="validationForm" @clicked-delete="confirmDelete">
      <hr class="p-0 m-0 mb-1" />
      <validation-observer ref="userRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Nome do Grupo *">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-input v-model="record.name" autocomplete="off" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <hr />

          <h5 class="text-center m-0">Permissionamento</h5>
          <small style="display: block" class="text-center text-muted">Arraste as permissões para adicionar ou
            remover.</small>

          <!-- Permissões -->
          <b-row class="mt-2">
            <b-col md="6">
              <Draggable v-if="ready1 && ready2" title="Permissões Atribuídas" icon="ArrowDownCircleIcon" :perPage="7"
                :list="record.permissions" :height="draggableHeight" @change="recordChange"
                @greatestHeight="getDraggableHeight" />
            </b-col>
            <b-col md="6">
              <Draggable v-if="ready1 && ready2" title="Permissões Disponíveis" icon="ArrowDownCircleIcon" :perPage="7"
                :list="permissions" :height="draggableHeight" @change="PermissionsChange"
                @greatestHeight="getDraggableHeight" />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </viewcard--c>
  </div>
</template>
<script>
import _groupPermissionService from "@/services/group-permissions";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import Draggable from "@/components/Draggable.vue";
import { required, email } from "@validations";
import { BFormInput } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    Draggable,
    ClassificationModal
  },
  data() {
    return {
      btedit: { permission: `permission.permissiongroup.edit` },
      btcreate: { permission: `permission.permissiongroup.create` },
      btdelete: { permission: `permission.permissiongroup.delete` },
      loading: false,
      isOpen: true,
      permissions: [],
      permissionsAux: [],
      record: {
        id: 0,
        name: "",
        permissions: [],
        permissionsAux: [],
      },
      ready1: false,
      ready2: false,
      draggableHeight: 0,
    };
  },
  created() {
    this.getpermissions();
    localize("pt_BR", pt_br);
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    getDraggableHeight(_height) {
      if (_height > this.draggableHeight) {
        this.draggableHeight = _height;
      }
    },
    validationForm() {
      this.$refs.userRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _groupPermissionService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.record.permissions = this.record.permissions.map((m) => {
              return {
                label: this.dsPermission(m.name),
                value: m.name,
              };
            });

            // limpar outras
            let permissionAUX = [];
            this.permissions.forEach((_ele) => {
              if (
                !this.record.permissions.some((s) => s.value === _ele.value)
              ) {
                permissionAUX.push(_ele);
              }
            });
            this.permissions = permissionAUX;

            // não usar o mesmo endereço de memoria
            this.permissionsAux = this.permissions.map((m) => m);
            this.record.permissionsAux = this.record.permissions.map((m) => m);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {
            this.loading = false;
            this.ready1 = true;
          });
      } else {
        this.ready1 = true;
      }
    },
    getpermissions() {
      _groupPermissionService
        .showPermissions()
        .then((res) => {
          this.permissions = res;
          this.permissionsAux = res.map((m) => m);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.ready2 = true;
        });
    },
    dsPermission(_permit) {
      if (this.permissions.some((s) => s.value === _permit)) {
        return this.permissions.filter((f) => f.value === _permit)[0].label;
      }
      return _permit;
    },
    save() {
      this.record.permissions = this.record.permissions.map((m) => {
        return {
          name: m.value,
        };
      });

      const payload = { data: { ...this.record } };
      const _createOrUpdate =
        this.record.id <= 0
          ? _groupPermissionService.create(payload)
          : _groupPermissionService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Grupo salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.loading = true;
      _groupPermissionService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    recordChange(payload) {
      this.record.permissions = payload;
    },
    PermissionsChange(payload) {
      this.permissions = payload;
    },
  },
};
</script>